import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"

import { Helmet } from "react-helmet"
import SEO from "../components/seo"

import ProductPageComponent from "../components/ProductPageComponent"

import K10StandardImage from "../images/k10-standard-17d-pa.jpg"
import styled from "styled-components"

import DiskPadAssistImage from "../images/DiskPadAssist.jpg"
import DiskTractionImage from "../images/DiskTraction.jpg"
import OrbitalImage from "../images/orbital-deck-400.png"
import OrbitalImageThumb from "../images/orbital-deck-400.jpg"
import DiskPadImageLarge from "../images/DeckPadAssistLarge.jpg"
import DiskTractionImageLarge from "../images/DiskTractionLarge.jpg"
import K10Orbital from "../images/k10-standard-20o.jpg"

const K10PageStyle = styled.main`
  padding: 2rem;
`

const K10StandardPage = () => {
  return (
    <>
      <SEO title="Floor Scrubbers: K10 Standard Walk-Behind Industrial Cleaning Equipment - Kodiak Cleaning Equipment" />
      <Helmet>
        <title>
          Floor Scrubbers: K10 Standard Walk-Behind Industrial Cleaning
          Equipment - Kodiak Cleaning Equipment
        </title>
        <description></description>
      </Helmet>
      <MainLayout>
        <PageHeading
          chooseTitle="Choose Your Scrub Deck"
          pageHeading="K10 Standard Floor Scrubber"

          // description="Here is the info about the K3 scrubbers and what they are used for"
        />
        <K10PageStyle>
          <ProductPageComponent
            descriptionTitle1="Solution Tank Capacity:"
            descriptionTitle2="Run Time:"
            mainDiskImage={K10StandardImage}
            mainCylindricalImage={K10StandardImage}
            mainOrbitalImage={K10Orbital}
            diskTitle="K10 Standard"
            subTitle="Walk-Behind Small Floor Scrubber"
            diskDescription="This robust and budget friendly scrubber might be the last you have to buy!  Durability of construction and powerful cleaning performance are our key goals when designing machines.  Easily deliver clean floors in Fitness Centers, Car Shops, Fabrication Shops, and more.  The scrub brushes are installed and removed without tools to allow ease when switching between application’s needs.  The squeegee blades can be rotated or flipped without tools.  Ensure complete cleaning in a variety of sized applications with a reliable scrubber."
            diskCleaningPath="17 - 20in (43 - 51cm)"
            diskGallons="Sol: 10gal (38L) Rec: 10gal (38L)"
            diskRunTime=" Up to 3 hours"
            cylindricalTitle="K10 Standard"
            cylindricalDescription="This robust and budget friendly scrubber might be the last you have to buy!  Durability of construction and powerful cleaning performance are our key goals when designing machines.  Easily deliver clean floors in Fitness Centers, Car Shops, Fabrication Shops, and more.  The scrub brushes are installed and removed without tools to allow ease when switching between application’s needs.  The squeegee blades can be rotated or flipped without tools.  Ensure complete cleaning in a variety of sized applications with a reliable scrubber."
            cylindricalCleaningPath="17 - 20in (43 - 51cm)"
            cylindricalGallons="Sol: 10gal (38L) Rec: 10gal (38L)"
            cylindricalRunTime=" Up to 3 hours"
            orbitalTitle="K10 Standard"
            orbitalDescription="This robust and budget friendly scrubber might be the last you have to buy!  Durability of construction and powerful cleaning performance are our key goals when designing machines.  Easily deliver clean floors in Fitness Centers, Car Shops, Fabrication Shops, and more.  The scrub brushes are installed and removed without tools to allow ease when switching between application’s needs.  The squeegee blades can be rotated or flipped without tools.  Ensure complete cleaning in a variety of sized applications with a reliable scrubber."
            orbitalCleaningPath="17 - 20in (43 - 51cm)"
            orbitalGallons="Sol: 10gal (38L) Rec: 10gal (38L)"
            orbitalRunTime=" Up to 3 hours"
            DeckImageThumb1={DiskPadAssistImage}
            DeckImageThumb2={DiskTractionImage}
            DeckImageThumb3={OrbitalImageThumb}
            DeckImage1Description="Disk Pad Assist"
            DeckImage2Description="Disk Traction"
            DeckImage3Description="Orbital"
            DeckImageLarge1={DiskPadImageLarge}
            DeckImageLarge2={DiskTractionImageLarge}
            DeckImageLarge3={OrbitalImage}
            // deckTitle="Why Disk-Pad Assist?"
            deckDescription1="Lowest Maintenance Cost"
            deckDescription2="Brushes Individually Driven for Unmatched Performance"
            deckDescription3="Improved Cleaning of Irregular Floors"
            deckTitle2="Why Disk-Traction?"
            deckDescription4="Powered Reverse"
            deckDescription5="Ramp Climbing"
            deckDescription6="Operator Ease"
            // deckTitle="Why Orbital"
            deckDescription7="Chemical Free Stripping"
            deckDescription8="70% Reduction in Water Usage"
            deckDescription9="Floor Finish Prep & Recoat"
            brochurePdf="../k-k10s-k10c-BROCHURE-V20073.pdf"
            techPdf="../K10-Kodiak-Specs.pdf"
            partsPdf="../K-K10-S-P-EN.pdf"
            operatorPdf="../K-K10-STANDARD-OP-EN.pdf"
            displayHood="none"
            showPM="none"
            mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
            ></ProductPageComponent>
        </K10PageStyle>
      </MainLayout>
    </>
  )
}

export default K10StandardPage
